<template>
  <b-row>
    <b-col>
      <b-card>
        <div class="d-flex justify-content-between">
          <div>
            <h3>
              Adicione um Cliente
            </h3>
          </div>
          <div class="d-flex mt-50">
            <b-form-checkbox
              v-model="type"
              class="custom-control-primary"
              value="F"
              @change="changeType('F')"
            >
              Pessoa Física
            </b-form-checkbox>
            <b-form-checkbox
              v-model="type"
              class="custom-control-info ml-1 mr-1"
              value="J"
              @change="changeType('J')"
            >
              Pessoa Jurídica
            </b-form-checkbox>
            <b-form-checkbox
              v-model="type"
              class="custom-control-warning"
              value="E"
              @change="changeType('E')"
            >
              Estrangeiro
            </b-form-checkbox>
          </div>
        </div>
      </b-card>
      <b-card>
        <h4>Informações básicas</h4>
        <b-row class="mt-2">
          <b-col md="4">
            <b-form-group

              label="Nome *"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="customer.name"
                placeholder="Nome do Cliente"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'J'"
            md="4"
          >
            <b-form-group

              label="Nome fantasia:"
              label-for="fantasyName"
            >
              <b-form-input
                id="fantasyName"
                v-model="customer.fantasyName"
                placeholder="Nome fantasia do Cliente"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'F'"
            md="3"
          >
            <b-form-group
              label="CPF"
              label-for="cpf"
            >
              <b-form-input
                id="cpf"
                v-model="customer.document"
                placeholder="CPF"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'J'"
            md="3"
          >
            <b-form-group
              label="CNPJ"
              label-for="cnpj"
            >
              <b-form-input
                id="cnpj"
                v-model="customer.document"
                placeholder="CNPJ"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'E'"
            md="3"
          >
            <b-form-group
              label="Documento estrangeiro"
              label-for="document"
            >
              <b-form-input
                id="document"
                v-model="customer.document"
                placeholder="Documento estrangeiro"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'F'"
            md="3"
          >
            <b-form-group
              label="RG"
              label-for="rg"
            >
              <b-form-input
                id="rg"
                v-model="customer.rg"
                placeholder="RG"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="9">
            <b-form-group
              label="Observação:"
              label-for="obs"
            >
              <b-form-textarea
                id="obs"
                v-model="customer.obs"
                placeholder="Observação"
              />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <div class="text-center">
              <div class="mb-1">
                <label>Carteira de fiado ?</label>
              </div>
              <div class="d-flex justify-content-between">
                <b-form-checkbox
                  v-model="customer.wallet"
                  class="custom-control-info"
                  :value="false"
                >
                  Não
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="customer.wallet"
                  class="custom-control-warning"
                  :value="true"
                >
                  sim
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <h4>Informações de contato</h4>
        <b-row>
          <b-col md="2">
            <b-form-group
              label="Celular"
              label-for="cell"
            >
              <b-form-input
                id="cell"
                v-model="customer.cell"
                placeholder="(41) 9 9559-2885"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'J'"
            md="3"
          >
            <b-form-group
              label="Telefone"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="customer.phone"
                placeholder="(41) 9 9559-2885"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'J'"
            md="3"
          >
            <b-form-group
              label="Fax"
              label-for="fax"
            >
              <b-form-input
                id="fax"
                v-model="customer.fax"
                placeholder="(41) 9 9559-2885"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="customer.email"
                placeholder="email@teste.com.br"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'J'"
            md="4"
          >
            <b-form-group
              label="Email para NFe"
              label-for="emailNfe"
            >
              <b-form-input
                id="emailNfe"
                v-model="customer.emailNfe"
                :value="customer.emailNfe"
                placeholder="email@teste.com.br"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="type === 'J'"
            md="2"
          >
            <div class="text-center">
              <div class="mb-1">
                <label>Os emails são o mesmo ?</label>
              </div>
              <div class="d-flex justify-content-between">
                <b-form-checkbox
                  v-model="mailSame"
                  class="custom-control-info"
                  value="N"
                  @change="sameEmails('N')"
                >
                  Não
                </b-form-checkbox>
                <b-form-checkbox
                  v-model="mailSame"
                  class="custom-control-warning"
                  value="S"
                  @change="sameEmails('S')"
                >
                  sim
                </b-form-checkbox>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>
      <b-card>
        <div class="d-flex justify-content-between">
          <div class="mt-50">
            <h4>
              Endereço
            </h4>
          </div>
          <div class="d-flex">
            <b-form-input
              id="zipCode"
              v-model="customer.zipCode"
              :value="customer.zipCode"
              placeholder="CEP"
              type="number"
            />
            <b-button
              variant="flat-success"
              class="btn-icon ml-25"
              @click="getAdress()"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </div>
        <hr>
        <b-row>
          <b-col md="1">
            <b-form-group
              label="Pais"
              label-for="country"
            >
              <b-form-input
                id="country"
                v-model="customer.country"
                :value="customer.country"
                disabled
                placeholder="Pais"
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-form-group
              label="UF"
              label-for="state"
            >
              <b-form-input
                id="state"
                v-model="customer.state"
                :value="customer.state"
                disabled
                placeholder="UF"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Cidade"
              label-for="city"
            >
              <b-form-input
                id="city"
                v-model="customer.city"
                :value="customer.city"
                placeholder="Cidade"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Bairro"
              label-for="district"
            >
              <b-form-input
                id="district"
                v-model="customer.district"
                :value="customer.district"
                placeholder="Bairro"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Rua"
              label-for="street"
            >
              <b-form-input
                id="street"
                v-model="customer.street"
                :value="customer.street"
                placeholder="Rua"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="1">
            <b-form-group
              label="N°"
              label-for="number"
            >
              <b-form-input
                id="number"
                v-model="customer.number"
                :value="customer.number"
                placeholder="N°"
              />
            </b-form-group>
          </b-col>
          <b-col md="11">
            <b-form-group
              label="Complemento"
              label-for="complement"
            >
              <b-form-input
                id="complement"
                v-model="customer.complement"
                :value="customer.complement"
                placeholder="Complemento"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="type === 'J'">
        <div class="d-flex justify-content-between">
          <div class="mt-50">
            <h4>
              Endereço de cobrança
            </h4>
          </div>
          <div class="text-center">
            <div>
              <label>Os endereços são os mesmos ?</label>
            </div>
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                v-model="addressSame"
                class="custom-control-info mr-2"
                value="N"
                @change="sameAddress('N')"
              >
                Não
              </b-form-checkbox>
              <b-form-checkbox
                v-model="addressSame"
                class="custom-control-warning"
                value="S"
                @change="sameAddress('S')"
              >
                sim
              </b-form-checkbox>
            </div>
          </div>
          <div class="d-flex">
            <b-form-input
              id="billingZipCode"
              v-model="customer.billingZipCode"
              :value="customer.billingZipCode"
              placeholder="CEP"
              :disabled="addressSame === 'S'"
              type="number"
            />
            <b-button
              variant="flat-success"
              class="btn-icon ml-25"
              @click="getAdress('billing')"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
          </div>
        </div>
        <hr>
        <b-row v-if="addressSame === 'N'">
          <b-col md="1">
            <b-form-group
              label="Pais"
              label-for="billingCountry"
            >
              <b-form-input
                id="billingCountry"
                v-model="customer.billingCountry"
                :value="customer.billingCountry"
                disabled
                placeholder="Pais"
              />
            </b-form-group>
          </b-col>
          <b-col md="1">
            <b-form-group
              label="UF"
              label-for="billingState"
            >
              <b-form-input
                id="billingState"
                v-model="customer.billingState"
                :value="customer.billingState"
                disabled
                placeholder="UF"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Cidade"
              label-for="billingCity"
            >
              <b-form-input
                id="billingCity"
                v-model="customer.billingCity"
                :value="customer.billingCity"
                placeholder="Cidade"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Bairro"
              label-for="billingDistrict"
            >
              <b-form-input
                id="billingDistrict"
                v-model="customer.billingDistrict"
                :value="customer.billingDistrict"
                placeholder="Bairro"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Rua"
              label-for="billingStreet"
            >
              <b-form-input
                id="billingStreet"
                v-model="customer.billingStreet"
                :value="customer.billingStreet"
                placeholder="Rua"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="addressSame === 'N'">
          <b-col md="1">
            <b-form-group
              label="N°"
              label-for="billingNumber"
            >
              <b-form-input
                id="billingNumber"
                v-model="customer.billingNumber"
                :value="customer.billingNumber"
                placeholder="N°"
              />
            </b-form-group>
          </b-col>
          <b-col md="11">
            <b-form-group
              label="Complemento"
              label-for="billingComplement"
            >
              <b-form-input
                id="billingComplement"
                v-model="customer.billingComplement"
                :value="customer.billingComplement"
                placeholder="Complemento"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card class="mb-3">
        <b-button
          block
          variant="primary"
          @click="SalvarCliente()"
        >
          Salvar
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'Addclientes',
  data() {
    return {
      customer: {
        name: '',
        fantasyName: '',
        document: '',
        rg: '',
        obs: '',
        cell: '',
        phone: '',
        fax: '',
        email: '',
        wallet: false,
        emailNfe: '',
        zipCode: '',
        country: '',
        state: '',
        city: '',
        district: '',
        street: '',
        number: '',
        complement: '',
        billingZipCode: '',
        billingCountry: '',
        billingState: '',
        billingCity: '',
        billingDistrict: '',
        billingStreet: '',
        billingNumber: '',
        billingComplement: '',
      },
      type: 'F',
      Carteira: 0,
      mailSame: 'N',
      addressSame: 'N',
    }
  },
  async created() {
    this.$loading(true)
    await this.BuscaDadosCliente(this.$route.params.id)
  },
  methods: {
    sameAddress(value) {
      if (value === 'S') {
        this.addressSame = 'S'
        this.customer.billingZipCode = this.customer.zipCode
        this.customer.billingCountry = this.customer.country
        this.customer.billingState = this.customer.state
        this.customer.billingCity = this.customer.city
        this.customer.billingDistrict = this.customer.district
        this.customer.billingStreet = this.customer.street
        this.customer.billingNumber = this.customer.number
        this.customer.billingComplement = this.customer.complement
      }
      if (value === 'N') {
        this.addressSame = 'N'
        this.customer.billingZipCode = ''
        this.customer.billingCountry = ''
        this.customer.billingState = ''
        this.customer.billingCity = ''
        this.customer.billingDistrict = ''
        this.customer.billingStreet = ''
        this.customer.billingNumber = ''
        this.customer.billingComplement = ''
      }
    },
    // get adress by zipCode in brasil
    getAdress(field) {
      if (this.customer.zipCode.length === 8) {
        this.$http.get(`https://viacep.com.br/ws/${field === 'billing' ? this.customer.billingZipCode : this.customer.zipCode}/json/`)
          .then(response => {
            if (field === 'billing') {
              this.customer.billingCountry = 'Brasil'
              this.customer.billingState = response.data.uf
              this.customer.billingCity = response.data.localidade
              this.customer.billingDistrict = response.data.bairro
              this.customer.billingStreet = response.data.logradouro
            } else {
              this.customer.country = 'Brasil'
              this.customer.state = response.data.uf
              this.customer.city = response.data.localidade
              this.customer.district = response.data.bairro
              this.customer.street = response.data.logradouro
            }
          })
          .catch()
      }
    },
    sameEmails(value) {
      if (value === 'S') {
        this.mailSame = 'S'
        this.customer.emailNfe = this.customer.email
      }
      if (value === 'N') {
        this.mailSame = 'N'
        this.customer.emailNfe = ''
      }
    },
    changeType(value) {
      if (value === 'F') this.type = 'F'
      if (value === 'J') this.type = 'J'
      if (value === 'E') this.type = 'E'
    },
    VoltaPagina() {
      this.$router.push('/clientes')
    },
    async BuscaDadosCliente(id) {
      await this.$http.get(`customers/find/${id}`).then(resp => {
        this.customer = resp.data
        this.changeType(this.customer.type)
        this.customer.billingComplement = resp.data.billingcomplement
        if (this.customer.wallet === 0) this.customer.wallet = false
        if (this.customer.wallet === 1) this.customer.wallet = true
        if (this.customer.email === this.customer.emailNfe) this.mailSame = 'S'
        if (this.customer.zipCode === this.customer.billingZipCode) {
          if (this.customer.number === this.customer.billingNumber) {
            if (this.customer.complement === this.customer.billingcomplement) {
              this.addressSame = 'S'
            }
          }
        }
      })
      this.$loading(false)
    },
    SalvarCliente() {
      this.$loading(true)
      const obj = {
        dados: this.customer,
        type: this.type,
      }
      this.$http.post(`customers/update/${this.$route.params.id}`, obj).then(resp => {
        this.data = resp.data
        if (this.data.success) {
          this.$toast.success('Editado Com Sucesso')
          this.$router.push('/clientes')
        } else {
          this.$toast.error('Erro ao Editar o Cliente')
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
